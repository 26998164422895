<template>
  <div class="widget-content">
    <div class="main">
      <div v-if="objectIsEmpty(data)"
           class="no-data-text">
        {{$t('widgets.base.fetching')}}...
      </div>
      <div v-else
           class="data-container grid-wrapper grid-wrapper--top">
        <div class="live-tile grid-tile">
          <label class="grid-tile__label">{{$t('widgets.presenceRate.realTime')}}</label>
          <span class="presence-label grid-tile__value"
                :class="presenceClass">{{hasPresence ? $t('widgets.presenceRate.occupied') : $t('widgets.presenceRate.free')}}</span>
        </div>
        <div class="value-tile grid-tile">
          <label class="grid-tile__label">{{$t('widgets.presenceRate.occupationRate')}}<br />
            {{$t(`components.periodSelector.longNames.${intervalesLongNames[intervalTime]}`)}}</label>
          <div class="grid-tile__value">
            {{value}}{{unit}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from 'api';
import moment from 'moment';
import UtilsMixin from '@/app/mixins/utils';
import WidgetMixin from '@/app/mixins/widget';

export default {
  name: 'WidgetPresenceRate',
  mixins: [UtilsMixin, WidgetMixin],
  props: {
    id: {
      type: Number,
      default: null,
    },
    data: {
      type: Object,
      default: () => {},
    },
    duration: {
      type: Object,
      default: () => {},
    },
    period: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      value: 0,
      threshold: 0,
      timeElapsed: '',
      timeElapsedFromNow: '',
      intervalId: null,
      intervalTime: '24hours',
      simpleDateFormat: 'D MMM',
      dateFormat: 'D MMM YYYY',
      intervalesLongNames: {
        '24hours': 'last 24 hours',
        '1weeks': 'last week',
        '1months': 'last month',
        '1years': 'last year',
      },
    };
  },
  computed: {
    unit() {
      return this.data && this.data.unit ? this.data.unit : '';
    },
    hasPresence() {
      return this.data.livePresence >= 90;
    },
    presenceClass() {
      return {
        'presence-label--occupied': this.hasPresence,
        'presence-label--free': !this.hasPresence,
      };
    },
  },
  watch: {
    data(consoData) {
      this.updateData(consoData);
    },
    async period() {
      await this.updateWidgetDuration();
    }
  },
  mounted() {
    this.intervalTime = `${this.duration.number}${this.duration.string}`;
  },
  methods: {
    async updateWidgetDuration() {
      this.intervalTime = this.period.value;
      await api.widget.update({
        id: this.id,
        config: {
          duration: this.period.values,
        },
      });
      this.$parent.$emit('refresh');
    },
    updateData(data) {
      this.value = data.percentPresence;
      this.timeElapsedFromNow = moment(data.lastDate).fromNow();
      this.timeElapsed = moment(data.lastDate).format('D MMM YYYY, kk:mm');
    },
    valueStyle(valueLength) {
      return {
        'font-size': `${this.lengthToFontSize(valueLength)}rem`,
      };
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.grid-tile
  padding $space-1 $space-2

.widget-content
  display flex
  flex-direction column
  height 100%
  .main
    display flex
    flex-basis 80%
    justify-content center
    .value, .no-data-text
      display flex
      align-items center
      height 100%
      font-weight 900
      font-size 1.6rem
    .unit
      margin-left $space-little
      font-weight 300
  .data-container
    display flex
    flex-direction column
    align-items center
    .grid-tile__value
      width 100%
      text-align center
    .live-tile, .value-tile
      width 100%
    .value-tile
      flex-direction row
      align-items center
      padding-top $space-1
      border-top $grid-border
      border-bottom $grid-border
      .grid-tile__label
        flex 2
      .grid-tile__value
        flex 1
        font-size $fs-h3
    .presence-label
      text-transform uppercase
      font-weight 700
      font-size $fs-h1
      &--occupied
        color $negative
      &--free
        color $positive
</style>