<template>
  <div class="widget-content">
    <div class="main">
      <div v-if="objectIsEmpty(data)"
           class="no-data-text">
        fetching...
      </div>
      <div v-else-if="noData"
           class="no-data-text no-data-text--light">
        no data
      </div>
      <LineChart v-else
                 :chartData="chartData"
                 :options="lineChartOptions"
                 class="line-chart" />
    </div>
  </div>
</template>

<script>
import api from 'api';
import moment from 'moment';
import chroma from 'chroma-js';

import UtilsMixin from '@/app/mixins/utils';
import LineChart from '@/app/components/charts/line.vue';

export default {
  name: 'WidgetLineGraph',
  components: {
    LineChart,
  },
  mixins: [UtilsMixin],
  props: {
    id: {
      type: Number,
      default: null,
    },
    data: {
      type: Object,
      default: () => {},
    },
    duration: {
      type: Object,
      default: () => {},
    },
    period: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      intervalTime: '24hours',
      simpleDateFormat: 'D MMM',
      dateFormat: 'D MMM YYYY',
      labelDateFormat: 'D MMM YYYY, kk:mm',
      datasetsColors: chroma.scale(['#F7BBBF', '#000']).domain([0, 4]),
      lineChartOptions: {
        maintainAspectRatio: false,
        responsive: true,
        stacked: false,
        hoverMode: 'index',
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              margin: 0,
              gridLines: {
                display: false,
                drawBorder: false,
                tickMarkLength: 0,
              },
              ticks: {
                display: false,
                padding: 0,
              },
            },
          ],
        },
        tooltips: {
          backgroundColor: 'black',
          titleFontColor: 'white',
          bodyFontColor: 'white',
          displayColors: false,
          mode: 'index',
          intersect: false,
          position: 'nearest',
        },
      },
    };
  },
  computed: {
    noData() {
      return Object.keys(this.data.value).every(key => this.data.value[key].graph.length === 0);
    },
    chartData() {
      if (!this.data) return {};
      const datasets = [];
      let labels = [];
      this.checkLegendDisplay();
      Object.keys(this.data.value).forEach((k, i) => {
        const profile = this.data.value[k];
        if (labels.length === 0 && profile.graph !== 0) {
          labels = this.generateLabels(profile.graph);
        }
        const profileDataArray = profile.graph.map(entry => Number(entry.value.toFixed(2)));
        datasets.push({
          label: `${profile.info.measure} ${profile.info.unit}`,
          borderColor: this.datasetsColors(i),
          fill: false,
          data: profileDataArray,
          pointRadius: 0,
        });
      });
      return {
        datasets: datasets,
        labels: labels,
      };
    },
  },
  watch: {
    async period() {
      await this.updateWidgetDuration();
    }
  },
  mounted() {
    this.intervalTime = `${this.duration.number}${this.duration.string}`;
  },
  methods: {
    async updateWidgetDuration() {
      this.intervalTime = this.period.value;
      await api.widget.update({
        id: this.id,
        config: {
          duration: this.period.values,
        },
      });
      this.$parent.$emit('refresh');
    },
    generateLabels(graphData) {
      return graphData.map(e => moment(e.time).format(this.labelDateFormat));
    },
    checkLegendDisplay() {
      if (Object.keys(this.data.value).length >= 2) this.lineChartOptions.legend.display = true;
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.widget-content
  display flex
  flex-direction column
  height 100%
  .main
    display flex
    height 70%
    .no-data-text
      display flex
      justify-content center
      align-items center
      width 100%
      height 100%
      font-weight $fw-extra-bold
      font-size 3rem
      &--light
        font-weight $fw-light
    .line-chart
      overflow visible
      padding-top $space-1
      width 100%
      height 100%
</style>