<template>
  <q-layout ref="layout"
            class="dashboards">
    <q-page-container>
      <Dashboard v-if="currentDashboard"
                 :dashboard="currentDashboard" />
      <div v-else
           class="dashboard--empty">
           <div v-if="dashboards.length!==0">
             {{ $t('pages.dashboard.options.selectDashboard') }}
           </div>
           <div v-else>
            {{ $t('pages.dashboard.popUp.noDashboard') }}
           </div>
      </div>
    </q-page-container>
  </q-layout>
</template>

<script>

import Dashboard from '@/app/pages/dashboard/views/dashboard.vue';

export default {
  name: 'Dashboards',
  components: {
    Dashboard,
  },
  computed: {
    dashboards() {
      return this.$store.state.dashboard.collection;
    },
    currentDashboardId() {
      return this.$store.state.dashboard.currentDashboardId;
    },
    currentDashboard() {
      if (this.currentDashboardId === null) {
        return null;
      }
      const dash = this.dashboards.find(d => d.id === this.currentDashboardId);
      if (dash) {
        return dash;
      }
      return null;
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.dashboards
  min-height auto
  height 100%
  .dashboard--empty
    margin-left 250px
    padding $space-3
    width 100%
    font-size $fs-h1
</style>
