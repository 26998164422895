export default {
  methods: {
    // return font size in rem according to text length
    lengthToFontSize(length) {
      if (length <= 7) return 4.8;
      if (length >= 8 && length <= 11) return 3.5;
      if (length >= 12 && length <= 13) return 3;
      if (length >= 14 && length <= 16) return 2.5;
      if (length >= 17) return 2;
    },
  },
};
