<template>
  <q-step title="OAPP"
          default
          :order="1"
          :class="stepClass('oapp')"
          name="oapp">
    <div class="creation-step">
      <div class="creation-step__header">
        <span class="subtitle">First choose an oapp</span>
      </div>
      <div class="creation-step__content">
        <q-list class="list">
          <q-item v-for="(oapp, idx) in oappsWithWidget"
                  :key="idx"
                  :class="itemClass(oapp === selectedOapp)"
                  class="item"
                  @click.native="selectOapp(oapp)">
            <q-item-side>
              <component :is="oapp.icon"
                         class="oapp-icon" />
            </q-item-side>
            <q-item-main>
              {{oapp.name}}
            </q-item-main>
          </q-item>
        </q-list>
      </div>
    </div>
    <q-stepper-navigation>
      <q-btn v-if="selectedOapp"
             color="secondary"
             class="o-button o-button--shadows o-button--black o-button--hover"
             label="Next"
             @click="stepperRef.next()" />
    </q-stepper-navigation>
  </q-step>
</template>

<script>
import ElectricityPicto from '@/oapps/commons/pictos/electricity.vue';

export default {
  name: 'OappStep',
  components: {
    ElectricityPicto,
  },
  props: {
    currentStep: {
      type: String,
      default: '',
    },
    stepperRef: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectedOapp: null,
    };
  },
  computed: {
    installedOapps() {
      return this.$store.getters['oapp/installed'];
    },
    oappsWithWidget() {
      return this.installedOapps.filter(o => o.widgets && Object.keys(o.widgets).length > 0);
    },
  },
  methods: {
    selectOapp(oapp) {
      this.selectedOapp = oapp;
      this.$emit('select', oapp);
    },
    stepClass(stepName) {
      return {
        'q-stepper-step--current': this.currentStep === stepName,
      };
    },
    itemClass(isSelected) {
      return {
        'item--selected': isSelected,
      };
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.creation-step
  display flex
  flex 1
  flex-direction column
  height 90%
  .creation-step__header
    display flex
    flex-direction column
    justify-content center
    margin-bottom $space-2
    .subtitle
      margin-bottom $space-2
      font-size $fs-h2
  &__content
    display flex
    flex-direction column
    height 100%
    .space-selector
      margin $space-2 0
      font-size $fs-h2
      & >>> .vue-treeselect__control
        border-color $black

.list
  padding 0
  height 100%
  border unset
  .item
    margin-bottom $space-1
    border 1px solid $grey
    border-radius $border-radius-smooth
    background-color white
    font-size $fs-h2
    cursor pointer
    &:hover
      background-color darken(white, 2%)
    &--selected, &--selected:hover
      background-color black
      color white
    &:last-child
      margin-bottom 0

.q-stepper
  .q-stepper-nav
    justify-content flex-end
    height 10%

.oapp-icon
  width 35px
  height @width
</style>