<template>
  <q-step title="WIDGET"
          name="widget"
          :class="stepClass('widget')"
          :order="2">
    <div class="creation-step">
      <div class="creation-step__header">
        <span class="subtitle">Now choose the widget type you want</span>
      </div>
      <div class="creation-step__content">
        <q-list v-if="widgets"
                class="list list--widget">
          <q-item v-for="(widget, idx) in widgets"
                  :key="idx"
                  :class="itemClass(widget)"
                  class="item widget-item"
                  @click.native="selectWidget(widget)">
            <q-item-main>
              <q-item-tile class="widget-item__title">{{widget.title}}</q-item-tile>
              <q-item-tile class="widget-item__description">{{widget.description}}</q-item-tile>
            </q-item-main>
            <q-item-side>
              <q-item-tile class="widget-item__size">{{widgetSize(widget)}}</q-item-tile>
            </q-item-side>
          </q-item>
        </q-list>
        <div class="visual-container">
          <img :src="widgetScreenshotPath"
               alt="">
        </div>
      </div>
    </div>
    <q-stepper-navigation>
      <q-btn label="Back"
             class="back-button o-button"
             @click="stepperRef.previous()" />
      <q-btn v-if="selectedWidget"
             color="secondary"
             class="o-button o-button--shadows o-button--black o-button--hover"
             label="Next"
             @click="stepperRef.next()" />
    </q-stepper-navigation>
  </q-step>
</template>

<script>
import UtilsMixin from '@/app/mixins/utils';

export default {
  name: 'WidgetStep',
  mixins: [UtilsMixin],
  props: {
    widgets: {
      type: Array,
      default: () => [],
    },
    currentStep: {
      type: String,
      default: '',
    },
    stepperRef: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectedWidget: null,
      screenShotPath: '/assets/widgets/',
    };
  },
  computed: {
    widgetScreenshotPath() {
      if (!this.selectedWidget) return '';
      return `${this.screenShotPath}${this.selectedWidget.name}.jpg`;
    },
  },
  watch: {
    widgets() {
      this.selectedWidget = null;
    },
  },
  methods: {
    stepClass(stepName) {
      return {
        'q-stepper-step--current': this.currentStep === stepName,
      };
    },
    itemClass(widget) {
      return {
        'item--selected': widget === this.selectedWidget,
      };
    },
    widgetSize(widget) {
      return `${widget.h}x${widget.w}`;
    },
    selectWidget(widget) {
      this.selectedWidget = widget;
      this.$emit('select', widget);
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.creation-step
  display flex
  flex 1
  flex-direction column
  height 90%
  .creation-step__header
    display flex
    flex-direction column
    justify-content center
    margin-bottom $space-2
    .subtitle
      margin-bottom $space-2
      font-size $fs-h2
  &__content
    display flex
    flex-direction column
    height 100%
    .space-selector
      margin $space-2 0
      font-size $fs-h2
      & >>> .vue-treeselect__control
        border-color $black

.list
  padding 0
  height 100%
  border unset
  &--widget
    overflow auto
    max-height 190px
    height auto
  .item
    margin-bottom $space-1
    border 1px solid $grey
    border-radius $border-radius-smooth
    background-color white
    font-size $fs-h2
    cursor pointer
    &:hover
      background-color darken(white, 2%)
    &--selected, &--selected:hover
      background-color black
      color white
    &:last-child
      margin-bottom 0

.widget-item
  &.item--selected
    .widget-item__description
      color $grey
  .widget-item__title
    font-weight 700
  .widget-item__description
    color $dark
    font-size $fs-body

.q-stepper
  .q-stepper-nav
    justify-content flex-end
    height 10%

.back-button
  margin-right $space-1

.visual-container
  display flex
  flex 1
  justify-content center
  align-items center
  width 100%
  img
    width 40%
</style>