<template>
  <div class="widget-content">
    <div class="main">
      <div v-if="objectIsEmpty(data)"
           class="no-data-text">
        fetching...
      </div>
      <div v-else
           class="value"
           :class="valueClass"
           :style="valueStyle(`${value}${unit}`.length)">
        {{value}}
        <div class="unit">{{unit}}</div>
      </div>
    </div>
    <div class="description">
      <div class="live">
        <span class="live-dot" />
        <span class="live-label">LIVE</span>
      </div>
      <span class="time">
        {{timeElapsedFromNow}}
        <q-tooltip color="white">{{timeElapsed}}</q-tooltip>
      </span>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import UtilsMixin from '@/app/mixins/utils';
import WidgetMixin from '@/app/mixins/widget';

export default {
  name: 'WidgetLive',
  mixins: [UtilsMixin, WidgetMixin],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      value: 0,
      threshold: 0,
      timeElapsed: '',
      timeElapsedFromNow: '',
    };
  },
  computed: {
    unit() {
      return this.data && this.data.unit ? this.data.unit : '';
    },
  },
  watch: {
    data(liveData) {
      this.updateData(liveData);
    },
  },
  methods: {
    valueStyle(valueLength) {
      return {
        'font-size': `${this.lengthToFontSize(valueLength)}rem`,
      };
    },
    updateData(data) {
      this.value = data.value;
      this.timeElapsedFromNow = moment(data.lastDate).fromNow();
      this.timeElapsed = moment(data.lastDate).format('D MMM YYYY, kk:mm');
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.widget-content
  display flex
  flex-direction column
  height 100%
  .main
    display flex
    flex-basis 80%
    justify-content center
    align-items center
    .value, .no-data-text
      display flex
      align-items center
      height 100%
      font-weight 900
      font-size 3rem
    .unit
      margin-left $space-little
      font-weight 300
  .description
    display flex
    padding $space-2
    .live
      display flex
      align-items center
      .live-dot
        display block
        margin-right $space-little
        width 10px
        height @width
        border-radius 50%
        background-color $negative
      .live-label
        color $grey
        letter-spacing 1.5px
    .time
      margin-left auto
      font-weight 300
      font-size $fs-body
</style>