<template>
  <div class="widget-content">
    <div class="main">
      <div v-if="objectIsEmpty(data)"
           class="no-data-text">
        fetching...
      </div>
      <div v-else
           class="data-container">
        <div v-if="noAlarm"
             class="alarms-title">aucune alarme</div>
        <div v-else
             class="alarms">
          <label class="alarms-title">Alarmes en cours :</label>
          <ul class="alarms-list">
            <li v-for="alarm in data.listAlarms"
                :key="alarm.uuid"
                class="alarm">{{alarm.name}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from 'api';
import moment from 'moment';
import UtilsMixin from '@/app/mixins/utils';
import WidgetMixin from '@/app/mixins/widget';

export default {
  name: 'WidgetUTLAlarm',
  mixins: [UtilsMixin, WidgetMixin],
  props: {
    id: {
      type: Number,
      default: null,
    },
    data: {
      type: Object,
      default: () => {},
    },
    duration: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      value: 0,
      threshold: 0,
      timeElapsed: '',
      timeElapsedFromNow: '',
      intervalId: null,
      intervalTime: '24hours',
      simpleDateFormat: 'D MMM',
      dateFormat: 'D MMM YYYY',
    };
  },
  computed: {
    unit() {
      return this.data && this.data.unit ? this.data.unit : '';
    },
    noAlarm() {
      return this.data.listAlarms.length === 0;
    },
  },
  watch: {
    data(consoData) {
      this.updateData(consoData);
    },
  },
  methods: {
    async updateWidgetDuration(period) {
      this.intervalTime = period.value;
      await api.widget.update({
        id: this.id,
        config: {
          duration: period.values,
        },
      });
      this.$parent.$emit('refresh');
    },
    updateData(data) {
      this.value = data.percentPresence;
      this.timeElapsedFromNow = moment(data.lastDate).fromNow();
      this.timeElapsed = moment(data.lastDate).format('D MMM YYYY, kk:mm');
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.widget-content
  display flex
  flex-direction column
  height 100%
  .main
    display flex
    justify-content center
    align-items center
    height 100%
    .data-container
      display flex
      flex-direction column
      justify-content center
      align-items center
      width 100%
      height 100%
    .alarms-title
      margin-bottom $space-2
      font-size $fs-h2
    .alarms
      padding $space-2
      width 100%
      height 100%
      .alarms-list
        overflow-y auto
        padding-left $space-2
        width 100%
        height 90%
        .alarm
          margin $space-1 0
</style>