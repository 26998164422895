<template>
  <div class="widget-content">
    <div class="main">
      <div v-if="objectIsEmpty(data)"
           class="no-data-text">
        fetching...
      </div>
      <div v-else
           class="value"
           :class="valueClass"
           :style="valueStyle(`${value}${unit}`.length)">
        {{value}}
        <div class="unit">{{unit}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import api from 'api';
import moment from 'moment';
import UtilsMixin from '@/app/mixins/utils';
import WidgetMixin from '@/app/mixins/widget';

export default {
  name: 'ConsoSince',
  mixins: [UtilsMixin, WidgetMixin],
  props: {
    id: {
      type: Number,
      default: null,
    },
    data: {
      type: Object,
      default: () => {},
    },
    duration: {
      type: Object,
      default: () => {},
    },
    period: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      value: 0,
      threshold: 0,
      timeElapsed: '',
      timeElapsedFromNow: '',
      intervalId: null,
      intervalTime: '24hours',
      simpleDateFormat: 'D MMM',
      dateFormat: 'D MMM YYYY',
    };
  },
  computed: {
    unit() {
      return this.data && this.data.unit ? this.data.unit : '';
    },
  },
  watch: {
    data(consoData) {
      this.updateData(consoData);
    },
    async period() {
      await this.updateWidgetDuration();
    }
  },
  mounted() {
    this.intervalTime = `${this.duration.number}${this.duration.string}`;
  },
  methods: {
    async updateWidgetDuration() {
      this.intervalTime = this.period.value;
      await api.widget.update({
        id: this.id,
        config: {
          duration: this.period.values,
        },
      });
      this.$parent.$emit('refresh');
    },
    updateData(data) {
      this.value = data.value;
      this.timeElapsedFromNow = moment(data.lastDate).fromNow();
      this.timeElapsed = moment(data.lastDate).format('D MMM YYYY, kk:mm');
    },
    valueStyle(valueLength) {
      return {
        'font-size': `${this.lengthToFontSize(valueLength)}rem`,
      };
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.widget-content
  display flex
  flex-direction column
  height 100%
  .main
    display flex
    flex-basis 80%
    justify-content center
    align-items center
    .value, .no-data-text
      display flex
      align-items center
      height 100%
      font-weight 900
      font-size 3rem
    .unit
      margin-left $space-little
      font-weight 300
</style>