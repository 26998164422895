<template>
  <svg viewBox="0 0 91 171"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M23.576 8.11245L60.0453 9.12244L38.0682 98.2246L1.59895 97.2146L23.576 8.11245Z"
          fill="black" />
    <path d="M22.3921 159.35L47.1111 61.1623L90.7927 60.4285L22.3921 159.35Z"
          fill="black" />
  </svg>
</template>

<script>
export default {
  name: 'ElectricityPicto',
};
</script>
