<template>
  <div class="widget-template">
    <div class="widget__header">
      <h3 v-if="!editName"
          class="widget-title">{{title}}</h3>
      <input v-else
             ref="nameInput"
             v-model="widgetName"
             type="text"
             @keypress.enter="updateName"
             @keydown.esc="editName = false">
      <div class="widget-actions">
        <q-spinner-puff v-show="isFetching"
                        color="positive"
                        :size="20"
                        class="widget-spinner" />
        <div v-on-clickaway="onClickAway"
             class="menu-container">
          <q-icon name="mdi-dots-vertical"
                  class="widget-handle"
                  size="20px"
                  @click.native.self="toggleMenu">
            <div v-if="showMenu"
                 class="widget-menu o-button o-button--shadows">
              <q-list :insetSeparator="true">
                <q-item @click.native="toggleRename">{{$t('widgets.base.edit')}}</q-item>
                <q-item class="menu-item menu-item--delete"
                        @click.native="deleteWidget">{{$t('widgets.base.delete')}}</q-item>
              </q-list>
            </div>
          </q-icon>
        </div>
      </div>
    </div>
    <slot :data="widgetData" />
  </div>
</template>

<script>
import api from 'api';
import { mixin as clickaway } from 'vue-clickaway';

export default {
  name: 'WidgetBase',
  mixins: [clickaway],
  props: {
    title: {
      type: String,
      default: 'no name',
    },
    id: {
      type: Number,
      default: null,
    },
    dashboardId: {
      type: Number,
      default: null,
    },
    dashboardWidgetId: {
      type: Number,
      default: null,
    },
    intervalRefresh: {
      type: Number,
      default: 5000,
    },
    config: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showMenu: false,
      editName: false,
      widgetName: '',
      intervalID: null,
      widgetData: null,
      isFetching: false,
    };
  },
  created() {
    this.widgetName = this.title;
    this.fetchDataInterval();
    this.$on('refresh', this.fetchDataInterval);
  },
  destroyed() {
    clearInterval(this.intervalID);
  },
  methods: {
    onClickAway() {
      this.showMenu = false;
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
      this.$emit('menuOpen', this.dashboardWidgetId);
    },
    toggleRename() {
      this.editName = true;
      this.$nextTick(() => {
        this.$refs.nameInput.focus();
      });
      this.showMenu = false;
    },
    async deleteWidget() {
      try {
        await this.$q.dialog({
          title: `Delete this widget?`,
          ok: true,
          cancel: true,
          color: 'black',
        });
        try {
          await this.$store.dispatch('dashboard/deleteWidget', this.id);
          clearInterval(this.intervalID);
          this.$q.notify({ message: `Widget successfully deleted`, type: 'positive', position: 'bottom-left' });
        } catch (error) {
          this.$q.notify({ message: `Error when deleting widget`, type: 'negative', position: 'bottom-left' });
        }
      } catch (error) {
        // when click on dialog cancel button
      }
    },
    async updateName() {
      if (this.widgetName === '') return;
      try {
        await this.$store.dispatch('dashboard/updateWidget', {
          dashboardId: this.dashboardId,
          widget: { id: this.id, name: this.widgetName },
        });
        this.editName = false;
        this.$q.notify({ message: `Name successfully deleted`, type: 'positive', position: 'bottom-left' });
      } catch (error) {
        this.$q.notify({ message: error.message, type: 'negative', position: 'bottom-left' });
      }
    },
    fetchDataInterval() {
      clearInterval(this.intervalID);
      this.getData();
      this.intervalID = setInterval(() => {
        this.getData();
      }, this.intervalRefresh);
    },
    async getData() {
      this.isFetching = true;
      const { data } = await api.widget.data.read(this.dashboardWidgetId);
      this.isFetching = false;
      this.widgetData = data;
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.widget-template
  display flex
  flex-direction column
  width 100%
  height 100%
  border-radius $border-radius-smooth
  .widget__header
    display flex
    align-items center
    padding $space-2
    border-bottom 1px solid $light
    .widget-title
      margin 0
      letter-spacing 0.5px
      font-weight 500
      font-size $fs-h2
      line-height 1
    .widget-actions
      display flex
      align-items center
      margin-left auto
      .menu-container
        margin-left $space-1
      .widget-handle
        position relative
        margin-right -5px
        cursor pointer
        .widget-menu
          position absolute
          top 100%
          left 100%
          padding 0
          border-radius $border-radius-smooth
          background-color white
          .q-list
            padding 0
            border unset
            .q-item
              font-weight 400
              &:hover
                background-color $grey
              &:after
                left 0 // make the separator line full width
            .menu-item--delete
              color $negative
</style>