<template>
  <div class="widget-content">
    <div class="main">
      <div v-if="objectIsEmpty(data)"
           class="no-data-text">
        {{$t('widgets.base.fetching')}}...
      </div>
      <div v-else
           class="data-container">
        <div class="grid-wrapper grid-wrapper--top">
          <div class="quality-tile grid-tile">
            <label class="grid-tile__label">{{$t('widgets.hagerQai.quality')}}</label>
            <span class="grid-tile__value">
              {{data.message}}
            </span>
          </div>
        </div>
        <div class="values-container grid-wrapper">
          <div class="grid-tile">
            <label class="grid-tile__label">
              COVG
            </label>
            <span class="grid-tile__value"
                  :class="itemValueClass(data.values.covg.danger)">
              {{data.values.covg.value.toFixed(2)}}<span class="unit">µg/m<sup>3</sup></span>
            </span>
            <label class="threshold-label grid-tile__label">
              {{`${$t('widgets.hagerQai.threshold')} : ${data.thresholds.covg}`}}
            </label>
          </div>
          <div class="grid-tile">
            <label class="grid-tile__label">
              COVL
            </label>
            <span class="grid-tile__value"
                  :class="itemValueClass(data.values.covl.danger)">
              {{data.values.covl.value.toFixed(2)}}<span class="unit">µg/m<sup>3</sup></span>
            </span>
            <label class="threshold-label grid-tile__label">
              {{`${$t('widgets.hagerQai.threshold')} : ${data.thresholds.covl}`}}
            </label>
          </div>
          <div class="grid-tile">
            <label class="grid-tile__label">
              Aérosols
            </label>
            <span class="grid-tile__value"
                  :class="itemValueClass(data.values.pfine.danger)">
              {{data.values.pfine.value.toFixed(2)}}<span class="unit">Mpart/m<sup>3</sup></span>
            </span>
            <label class="threshold-label grid-tile__label">
              {{`${$t('widgets.hagerQai.threshold')} : ${data.thresholds.pfine}`}}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from 'api';
import moment from 'moment';
import UtilsMixin from '@/app/mixins/utils';
import WidgetMixin from '@/app/mixins/widget';

export default {
  name: 'WidgetHagerQai',
  mixins: [UtilsMixin, WidgetMixin],
  props: {
    id: {
      type: Number,
      default: null,
    },
    data: {
      type: Object,
      default: () => {},
    },
    duration: {
      type: Object,
      default: () => {},
    },
    period: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      value: 0,
      threshold: 0,
      timeElapsed: '',
      timeElapsedFromNow: '',
      intervalId: null,
      intervalTime: '24hours',
      simpleDateFormat: 'D MMM',
      dateFormat: 'D MMM YYYY',
    };
  },
  computed: {
    unit() {
      return this.data && this.data.unit ? this.data.unit : '';
    },
    hasPresence() {
      return this.data.livePresence >= 90;
    },
  },
  watch: {
    data(consoData) {
      this.updateData(consoData);
    },
    async period() {
      await this.updateWidgetDuration();
    }
  },
  mounted() {
    this.intervalTime = `${this.duration.number}${this.duration.string}`;
  },
  methods: {
    itemValueClass(danger) {
      return {
        'grid-tile__value--danger': danger,
      };
    },
    async updateWidgetDuration() {
      this.intervalTime = this.period.value;
      await api.widget.update({
        id: this.id,
        config: {
          duration: this.period.values,
        },
      });
      this.$parent.$emit('refresh');
    },
    updateData(data) {
      this.value = data.percentPresence;
      this.timeElapsedFromNow = moment(data.lastDate).fromNow();
      this.timeElapsed = moment(data.lastDate).format('D MMM YYYY, kk:mm');
    },
    valueStyle(valueLength) {
      return {
        'font-size': `${this.lengthToFontSize(valueLength)}rem`,
      };
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.grid-tile
  padding $space-1 $space-2

.widget-content
  display flex
  flex-direction column
  height 100%
  .main
    display flex
    flex-basis 80%
    justify-content center
    align-items center
    width 100%
    .data-container
      width 100%
    .values
      display flex
      justify-content space-between
      padding 0 $space-2
      width 100%
      .values-item
        display flex
        flex-direction column
      .values-item__value, .values-item__label, .values-item__threshold
        display flex
        justify-content center
      .values-item__value
        font-weight 700
        font-size $fs-h1
        &--danger
          color $negative
  .data-container
    display flex
    flex-direction column
    align-items center
    .values-container
      border-bottom $grid-border
      .grid-tile
        flex 1
        padding $space-little $space-2
        .grid-tile__value
          width 100%
          text-align center
          font-weight 700
          font-size $fs-h1
          &--danger
            color $negative
          .unit
            margin-left $space-little
            font-weight 300
            font-size $fs-h3
    .presence-label
      margin-bottom $space-1
      font-weight 300
      font-size $fs-big
    .threshold-label
      width 100%
      text-align center
  .quality-tile
    width 100%
    .grid-tile__value
      margin-top -15px
      width 100%
      text-align center
      text-transform uppercase
      font-weight 700
      font-size $fs-h1
</style>