<template>
  <div class="widget-content">
    <div class="main">
      <div v-if="objectIsEmpty(data)"
           class="no-data-text">
        fetching...
      </div>
      <div v-else
           class="data-container">
         <div class="current-value-container">
           <div class="current-value-container--value">{{currentValue}}</div>
           <div>Current</div>
         </div>
        <div
            class="donut-container">
          <DonutChart :chartData="donutData"
                      :options="donutOptions"
                      class="donut-chart">
          </DonutChart>
          <div class="donut-percent-value">{{percentDisplay}}</div>
        </div>
         <div class="goal-value-container">
           <div class="current-value-container--value">{{goalValue}}</div>
           <div>Goal</div>
         </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from 'api';
import moment from 'moment';

import ColorsMixin from '@/app/mixins/colors';
import UtilsMixin from '@/app/mixins/utils';
import DonutChart from '@/app/components/charts/donut.vue';

export default {
  name: 'WidgetGaugeChart',
  components: {
    DonutChart,
  },
  mixins: [ColorsMixin, UtilsMixin],
  props: {
    id: {
      type: Number,
      default: null,
    },
    data: {
      type: Object,
      default: () => {},
    },
    duration: {
      type: Object,
      default: () => {},
    },
    period: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      donutData: {},
      intervalTime: '24hours',
      simpleDateFormat: 'D MMM',
      dateFormat: 'D MMM YYYY',
      donutOptions: {
        tooltips: {
          callbacks: {
            label: item => {
              if (item.index === 0) return this.currentValue;
              if (item.index === 1) return this.goalValue;
            },
          },
        },
        cutoutPercentage: 80,
        rotation: Math.PI,
        circumference: Math.PI,
        legend: {
          display: false,
        },
        maintainAspectRatio: false,
      },
    };
  },
  computed: {
    unit() {
      return this.data.info.profile.unit || 'no unit';
    },
    currentValue() {
      if (!this.data) return null;
      return `${this.data.value.total} ${this.unit}`;
    },
    goalValue() {
      if (!this.data) return null;
      return `${this.data.info.config.goal} ${this.unit}`;
    },
    percentValue() {
      if (!this.data) return null;
      const current = this.data.value.total;
      const goal = this.data.info.config.goal;
      const percent = (current / goal) * 100;
      return percent;
    },
    percentDisplay() {
      if (!this.data) return null;
      return `${this.percentValue.toFixed(0)}%`;
    },
  },
  watch: {
    data() {
      this.updateData();
    },
    async period() {
      await this.updateWidgetDuration();
    }
  },
  mounted() {
    this.updateData();
    this.intervalTime = `${this.duration.number}${this.duration.string}`;
  },
  methods: {
    async updateWidgetDuration() {
      this.intervalTime = this.period.value;
      await api.widget.update({
        id: this.id,
        config: {
          duration: this.period.values,
        },
      });
      this.$parent.$emit('refresh');
    },
    updateData() {
      if (!this.data) return;
      const gaugePercent = this.percentValue > 100 ? 100 : this.percentValue;
      const data = [gaugePercent, 100 - gaugePercent];
      this.donutData = {
        datasets: [
          {
            data: data,
            borderColor: 'transparent',
            backgroundColor: [this.colors.darkPink, this.colors.light],
            hoverBackgroundColor: [this.colors.darkPink, this.colors.light],
            weight: 8,
          },
        ],
        labels: [`current ${this.data.info.profile.name}`, 'Goal'],
      };
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.widget-content
  display flex
  flex-direction column
  height 100%
  .main
    display flex
    height 70%
    .no-data-text
      display flex
      justify-content center
      align-items center
      width 100%
      height 100%
      font-weight 900
      font-size 3rem
    .data-container
      display flex
      width 100%
      align-items center
      justify-content space-around
      .current-value-container
        text-align center
        &--value
          font-weight 700
          font-size $fs-h1
      .goal-value-container
        text-align center
        &--value
          font-weight 700
          font-size $fs-h1
      .donut-container
        position relative
        width 40%
        height 100%
        .donut-percent-value
          position absolute
          bottom 20%
          left 50%
          z-index -1
          font-weight $fw-bold
          font-size $fs-big
          transform translateX(-50%)
      .donut-chart
        margin auto
        width 60%
        height 100%
</style>