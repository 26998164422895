<template>
  <div class="dashboard">
    <div class="dashboard__header">
      <div class="bloc">
        <template v-if="editName">
          <input ref="nameInput"
                v-model="newDashboardName"
                class="dashboard__name-input"
                @keypress.enter="updateName"
                @keydown.esc="editName = false" />
          <button class="close-edit-name o-button o-button--shadows o-button--squared o-button--hover"
                  @click="updateName">
            {{$t('pages.dashboard.options.done')}}
          </button>
        </template>
        <h2 v-show="!editName"
            class="dashboard__name"
            @dblclick="showInput">{{dashboard.name}}</h2>
      </div>
      <div class="description">
        <PeriodSelector v-model="intervalTime"
                        class="period-selector"
                        @input="updateDuration" />
        <div class="date">
          <span class="date-from">{{formatedInterval.from}}</span>
          <span class="date-to">
            <span class="date-label">to:</span>
            {{formatedInterval.to}}
          </span>
        </div>
      </div>
      <div class="bloc">
        <WidgetCreation v-if="rights.WRITE"
                        class="widget-creation"
                        @creation="addWidget" />
        <div v-if="rights.WRITE"
            v-on-clickaway="onClickAway"
            class="dashboard-menu">
          <span class="dashboard-menu__title"
                @click.self="toggleShowMenu">
            {{$t('pages.dashboard.options.title')}}
            <q-icon name="mdi-settings-outline"
                    size="20px"
                    @click.native.self="toggleShowMenu" />
            <div v-if="showMenu"
                class="dashboard-menu-list o-button o-button--shadows">
              <q-list :insetSeparator="true">
                <q-item @click.native="toggleEditName">{{$t('pages.dashboard.options.editName')}}</q-item>
                <q-item class="item-delete"
                        @click.native="deleteDashboard">{{$t('pages.dashboard.options.deleteDashboard')}}</q-item>
              </q-list>
            </div>
          </span>
        </div>
      </div>
    </div>
    <div class="widget-board">
      <grid-layout :layout.sync="dashboard.dashboardWidgets"
                   :rowHeight="200"
                   :isResizable="false"
                   :responsive="true"
                   :margin="[24, 24]"
                   :autoSize="false"
                   :verticalCompact="true"
                   :useCssTransform="false"
                   :breakpoints="{ lg: 1500, md: 996, sm: 768, xs: 480, xxs: 0 }"
                   :cols="{ lg: 11, md: 8, sm: 6, xs: 4, xxs: 2 }">
        <grid-item v-for="item in dashboard.dashboardWidgets"
                   :key="item.i"
                   :x="item.x"
                   :y="item.y"
                   :w="item.w"
                   :h="item.h"
                   :i="item.i"
                   dragAllowFrom=".widget__header, .widget-title"
                   dragIgnoreFrom=".widget-content, .widget-handle"
                   class="layout-item"
                   :class="gridItemClass(item.i)"
                   @moved="updateWidgetPosition">
          <widgetBase :id="item.id"
                      :dashboardId="dashboard.id"
                      :dashboardWidgetId="item.i"
                      :config="item.config"
                      v-bind="item.widget"
                      :title="item.config.name"
                      @menuOpen="widgetMenuIsOpen">
            <template slot-scope="{data}">
              <component :id="item.id"
                         :is="widgets[item.widget.type]"
                         :duration="item.config.duration"
                         :data="data"
                         :period="period" />
            </template>
          </WidgetBase>
        </grid-item>
      </grid-layout>
    </div>
  </div>
</template>

<script>
import { GridLayout, GridItem } from 'vue-grid-layout';
import { mixin as clickaway } from 'vue-clickaway';
import moment from 'moment';

import WidgetCreation from '@/app/pages/dashboard/components/widget-creation.vue';
import WidgetBase from '@/oapps/widgets/widget-base.vue';
//WIDGETS
import WidgetLive from '@/oapps/widgets/widget-live.vue';
import WidgetConsoSince from '@/oapps/widgets/widget-conso-since.vue';
import WidgetRt2012Graph from '@/oapps/widgets/widget-rt2012-graph.vue';
import WidgetGaugeChart from '@/oapps/widgets/widget-gauge-chart.vue';
import WidgetSumGaugeChart from '@/oapps/widgets/widget-sum-gauge-chart.vue';
import WidgetLineGraph from '@/oapps/widgets/widget-line-graph.vue';
import WidgetThresholdAlert from '@/oapps/widgets/widget-threshold-alert.vue';
import WidgetTotalSince from '@/oapps/widgets/widget-total-since.vue';
import WidgetPresenceRate from '@/oapps/widgets/widget-presence-rate.vue';
import WidgetUTLAlarm from '@/oapps/widgets/widget-utl-alarm.vue';
import WidgetHagerQai from '@/oapps/widgets/widget-hager-qai.vue';
import PeriodSelector from '@/oapps/widgets/period-selector.vue';

export default {
  name: 'Dashboard',
  components: {
    GridLayout,
    GridItem,
    PeriodSelector,
    WidgetBase,
    WidgetCreation,
  },
  mixins: [clickaway],
  props: {
    dashboard: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showMenu: false,
      editName: false,
      widgetMenuOpen: null,
      widgets: {
        liveValue: WidgetLive,
        consoSinceDuration: WidgetConsoSince,
        electricityRt2012Graph: WidgetRt2012Graph,
        gaugeChart: WidgetGaugeChart,
        sumGaugeChart: WidgetSumGaugeChart,
        simpleLineGraph: WidgetLineGraph,
        multiLineGraph: WidgetLineGraph,
        thresholdAlert: WidgetThresholdAlert,
        totalSince: WidgetTotalSince,
        presenceRate: WidgetPresenceRate,
        UTLAlarms: WidgetUTLAlarm,
        hagerQai: WidgetHagerQai,
      },
      newDashboardName: this.dashboard.name,
      intervalTime: '24hours',
      period: {},
      simpleDateFormat: 'D MMM',
      dateFormat: 'D MMM YYYY',
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    rights() {
      return this.user.featuresRights.dashboard;
    },
    formatedInterval() {
      if (!this.period.values) return { from: moment().subtract(1, 'day').format(this.simpleDateFormat), to: moment().format(this.dateFormat) };
      const { number: periodNumber, string: periodString } = this.period.values;
      return {
        from: moment().subtract(periodNumber, periodString).format(this.simpleDateFormat),
        to: moment().format(this.dateFormat),
      };
    },
  },
  watch: {
    dashboard() {
      // set values for a dashboard selection
      this.newDashboardName = this.dashboard.name;
      this.editName = false;
    }
  },
  methods: {
    updateDuration(periodData) {
      this.intervalTime = periodData.value;
      this.period = periodData;
    },
    toggleShowMenu() {
      this.showMenu = !this.showMenu;
    },
    toggleEditName() {
      this.editName = true;
      this.$nextTick(() => {
        this.$refs.nameInput.focus();
      });
      this.showMenu = false;
    },
    onClickAway() {
      this.showMenu = false;
    },
    async updateName() {
      this.editName = false;
      if (this.newDashboardName === this.dashboard.name || this.newDashboardName === '') {
        // set newDashboardName as dashboard.name to avoid empty input during renaming the dashboard
        this.newDashboardName = this.dashboard.name;
        return; // No change in dashboard name
      }
      const isDashboardPresent = this.checkIfDashboardNameExists();
      if (isDashboardPresent) {
        this.$q.notify({ message: this.$t('pages.dashboard.addDashboard.dashboardNameExist') + this.newDashboardName, type: 'negative', position: 'bottom-left' });
        this.newDashboardName = this.dashboard.name;
        return;
      }
      try {
        this.dashboard.name = this.newDashboardName;
        await this.$store.dispatch('dashboard/updateDashboard', this.dashboard);
        this.$q.notify({ message: this.$t('pages.dashboard.toasts.dashboardUpdated'), type: 'positive', position: 'bottom-left' });
      } catch (error) {
        this.$q.notify({ message: this.$t('pages.dashboard.toasts.errorDashboardUpdate'), type: 'negative', position: 'bottom-left' });
      }
    },
    checkIfDashboardNameExists() {
      const dashboards = this.$store.state.dashboard.collection;
      const presentDashboardNames = dashboards.map(dashboard => dashboard.name);
      return presentDashboardNames.includes(this.newDashboardName);
    },
    showInput() {
      this.editName = true;
      this.$nextTick(() => {
        this.$refs.nameInput.focus();
      });
    },
    async deleteDashboard() {
      try {
        await this.$q.dialog({
          title: `Delete this dashboard?`,
          ok: true,
          cancel: true,
          color: 'black',
        });
        try {
          await this.$store.dispatch('dashboard/deleteDashboard', this.dashboard.id);
          const dashboards = this.$store.state.dashboard.collection;
          if (dashboards.length === 1) this.$store.commit('dashboard/setCurrentDashboardId', dashboards[0].id); //set first dashboard in the list as currentDashboard
          this.$q.notify({ message: this.$t('pages.dashboard.toasts.dashboardDeleted'), type: 'positive', position: 'bottom-left' });
        } catch (error) {
          this.$q.notify({ message: this.$t('pages.dashboard.toasts.errorDashboardDeleted'), type: 'negative', position: 'bottom-left' });
        }
      } catch (error) {
        // when click on dialog cancel button
      }
    },
    // WIDGETS
    widgetMenuIsOpen(widgetId) {
      this.widgetMenuOpen = widgetId;
    },
    gridItemClass(widgetId) {
      return {
        'layout-item--menu-open': this.widgetMenuOpen && this.widgetMenuOpen === widgetId,
      };
    },
    async addWidget(newWidget) {
      try {
        await this.$store.dispatch('dashboard/createWidget', { dashboardId: this.dashboard.id, newWidget });
        this.$q.notify({ message: this.$t('pages.dashboard.toasts.widgetAddedToDashboard'), type: 'positive', position: 'bottom-left' });
      } catch (error) {
        this.$q.notify({
          message: this.$t('pages.dashboard.toasts.errorAddingWidgetToDashboard'),
          type: 'negative',
          position: 'bottom-left',
        });
      }
    },
    async updateWidgetPosition(widgetId, newX, newY) {
      try {
        await this.$store.dispatch('dashboard/updateWidget', {
          dashboardId: this.dashboard.id,
          widget: {
            id: widgetId,
            x: newX,
            y: newY,
          },
        });
      } catch (error) {
        this.$q.notify({ message: `Error when updating widget position`, type: 'negative', position: 'bottom-left' });
      }
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

$dashboard-title-fs = 2.3rem

.dashboard
  overflow-y auto
  height calc(100vh - 50px)
  .dashboard__header
    display flex
    align-items center
    justify-content space-between
    align-content center
    background-color $dash-light
    flex-wrap wrap
    padding $space-2
    .dashboard__name-input
      font-size $fs-h2
    .dashboard__name
      position relative
      font-size $dashboard-title-fs
      &:after
        position absolute
        bottom -8px
        width 100%
        content ''
    .dashboard-menu
      display flex
      align-items center
      position relative
      cursor pointer
      user-select none
      .dashboard-menu__title
        font-size $fs-h2
      .dashboard-menu-list
        position absolute
        top 150%
        right 0
        z-index 10
        padding 0
        width 130px
        border-radius $border-radius-smooth
        background-color white
        .q-list
          padding 0
          border unset
          .q-item
            font-weight 400
            &:hover
              background-color $grey
            &:after
              left 0 // make the separator line full width
          .item-delete
            color $negative
  .vue-grid-layout >>> .vue-grid-placeholder
    border-radius $border-radius
    background-color black
  .widget-board
    padding $space-2

.layout-item
  z-index 1
  border-radius $border-radius
  background-color white
  box-shadow $shadow-elevation-1
  &--menu-open
    z-index 2

.description
  display flex
  flex-direction row
  flex-wrap wrap
  align-items center
  justify-content center
  .date
    padding $space-1
    display flex
    flex-wrap wrap
    font-weight 300
    .date-from, .date-to
      flex 1
    .date-from
      text-align right
    .date-to
      white-space nowrap
    .date-label
      margin-right $space-little
      margin-left $space-1
      color $grey
      text-transform uppercase
.bloc
  display flex
  gap 5px
</style>