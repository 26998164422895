<template>
  <div class="widget-content">
    <div v-if="objectIsEmpty(data)"
         class="no-data-text">
      fetching...
    </div>
    <div v-else
         class="graph-container">
      <div class="graph">
        <LineGraph class="line-graph"
                   :data="currentPostData"
                   :unit="unit.graph"
                   :height="412" />
      </div>
      <div class="rt-posts">
        <q-list class="post-list">
          <q-item v-for="(post, key) in posts"
                  :key="key"
                  :class="postClass(key)"
                  class="post"
                  @click.native="selectPost(key)">
            <q-item-main class="post-main">
              <q-item-tile class="post-title">
                {{key}}
              </q-item-tile>
              <q-item-tile class="post-value">
                {{post.consoSinceDuration}}
                <span class="unit">{{unit.live}}</span>
              </q-item-tile>
            </q-item-main>
          </q-item>
        </q-list>
      </div>
    </div>
  </div>
</template>

<script>
import api from 'api';
import moment from 'moment';
import LineGraph from './commons/line-graph.vue';
import UtilsMixin from '@/app/mixins/utils';

export default {
  name: 'WidgetRt2012Graph',
  components: {
    LineGraph,
  },
  mixins: [UtilsMixin],
  props: {
    id: {
      type: Number,
      default: null,
    },
    data: {
      type: Object,
      default: () => {},
    },
    duration: {
      type: Object,
      default: () => {},
    },
    period: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      intervalId: null,
      intervalTime: '24hours',
      interval: null,
      selectedPost: 'Lighting',
      simpleDateFormat: 'D MMM',
      dateFormat: 'D MMM YYYY',
      durationOptions: [
        { label: 'last 24h', value: '24hours' },
        { label: 'last week', value: '1weeks' },
        { label: 'last month', value: '1months' },
        { label: 'last year', value: '1years' },
      ],
    };
  },
  computed: {
    unit() {
      return this.data && this.data.unit ? this.data.unit : '';
    },
    posts() {
      if (!this.data) return null;
      return this.data.value;
    },
    currentPostData() {
      if (!this.posts) return [];
      return Object.entries(this.posts[this.selectedPost].graph).reduce(
        (acc, i) => {
          acc.labels.push(moment(i[0]).format(this.dateFormat));
          acc.values.push(i[1]);
          return acc;
        },
        { labels: [], values: [] }
      );
    },
  },
  watch: {
    async period() {
      await this.updateWidgetDuration();
    }
  },
  mounted() {
    this.intervalTime = `${this.duration.number}${this.duration.string}`;
  },
  methods: {
    async updateWidgetDuration() {
      this.intervalTime = this.period.value;
      await api.widget.update({
        id: this.id,
        config: {
          duration: this.period.values,
        },
      });
      this.$parent.$emit('refresh');
    },
    selectPost(postName) {
      this.selectedPost = postName;
    },
    postClass(postName) {
      return {
        'post--selected': postName === this.selectedPost,
      };
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.widget-content
  display flex
  flex-direction column
  height 100%
  .graph-container
    display flex
    flex 1
    align-items flex-start
    padding $space-2
  .no-data-text
    display flex
    justify-content center
    align-items center
    width 100%
    height 100%
    font-weight 900
    font-size 4rem
  .rt-posts
    width 35%
    height 100%
    .post-list
      display flex
      flex-direction column
      padding 0
      width 100%
      height 100%
      border unset
      .post
        flex-grow 1
        padding $space-1
        border-top-right-radius $border-radius-smooth
        border-bottom-right-radius $border-radius-smooth
        cursor pointer
        &:last-item
          margin-bottom 0
        .post-title
          text-align right
          font-weight 300
          font-size $fs-h3
        .post-value
          text-align right
          font-weight 900
          font-size 1.6rem
          .unit
            font-weight 300
            font-size $fs-h2
        &--selected
          background-color $dash-light
          .post-title
            font-weight 700
  .graph
    display flex
    align-items center
    margin-right 0
    padding $space-1
    width 65%
    height 100%
    border-top-left-radius $border-radius-smooth
    border-bottom-left-radius $border-radius-smooth
    background-color $dash-light
    .line-graph
      width 100%
</style>