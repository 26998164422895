<template>
  <div class="widget-content">
    <div class="main">
      <div v-if="objectIsEmpty(data)"
           class="no-data-text">
        fetching...
      </div>
      <template v-else>
        <div class="threshold-values">
          <div class="value-threshold">{{`${data.threshold}${unit}`}}</div>
          <div class="value"
               :class="valueClass"
               :style="valueStyle(`${value}${unit}`.length + 4)">
            {{value}}
            <div class="unit">{{unit}}</div>
          </div>
          <div class="percent-threshold">{{`${percent}%`}}</div>
        </div>
        <q-progress :percentage="percent"
                    :color="progressBarColor"
                    height="8px"
                    class="alert-percentage-loading" />
      </template>
    </div>
    <div class="description">
      <div class="live">
        <span class="live-dot" />
        <span class="live-label">LIVE</span>
      </div>
      <span class="time">
        {{timeElapsedFromNow}}
        <q-tooltip color="white">{{timeElapsed}}</q-tooltip>
      </span>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import UtilsMixin from '@/app/mixins/utils';
import WidgetMixin from '@/app/mixins/widget';

export default {
  name: 'WidgetAlertThreshold',
  mixins: [UtilsMixin, WidgetMixin],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      value: 0,
      threshold: 0,
      timeElapsed: '',
      timeElapsedFromNow: '',
      acknowledgeAlert: false,
    };
  },
  computed: {
    unit() {
      return this.data && this.data.unit ? this.data.unit : '';
    },
    percent() {
      return this.data.percent;
    },
    progressBarColor() {
      // black
      if (this.percent < 80) return 'secondary';
      // orange
      else if (this.percent >= 80 && this.percent < 95) return 'warning';
      // red
      else return 'negative';
    },
    // classes
    valueClass() {
      return {
        'value--orange-color': this.percent >= 80 && this.percent < 95,
        'value--red-color': this.percent >= 95,
      };
    },
  },
  watch: {
    data: {
      handler(liveData) {
        this.updateData(liveData);
        if (liveData.alert && !this.acknowledgeAlert) {
          this.$q.notify({
            type: 'negative',
            message: liveData.info.config.name,
            position: 'bottom-right',
            closeBtn: true,
            onDismiss: () => (this.acknowledgeAlert = true),
          });
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.updateData(this.data);
  },
  methods: {
    valueStyle(valueLength) {
      return {
        'font-size': `${this.lengthToFontSize(valueLength)}rem`,
      };
    },
    updateData(data) {
      if (!data) return;
      this.value = data.value;
      this.timeElapsedFromNow = moment(data.lastDate).fromNow();
      this.timeElapsed = moment(data.lastDate).format('D MMM YYYY, kk:mm');
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.widget-content
  display flex
  flex-direction column
  height 100%
  .main
    display flex
    flex-basis 80%
    justify-content center
    align-items center
    .threshold-values
      display flex
      flex 1
      flex-direction column
      .value-threshold, .percent-threshold
        text-align right
    .value, .no-data-text
      font-weight 900
      font-size 3rem
    .value
      display flex
      justify-content flex-end
      &--orange-color
        color $warning
      &--red-color
        color $negative
    .unit
      margin-left $space-little
      font-weight 300
    .alert-percentage-loading
      margin 0 -16px
      width 30%
      transform rotate(-90deg)
  .description
    display flex
    padding $space-2
    .live
      display flex
      align-items center
      .live-dot
        display block
        margin-right $space-little
        width 10px
        height @width
        border-radius 50%
        background-color $negative
      .live-label
        color $grey
        letter-spacing 1.5px
    .time
      margin-left auto
      font-weight 300
      font-size $fs-body
</style>