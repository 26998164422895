<template>
  <div>
    <div class="button-add-widget o-button o-button--shadows o-button--hover o-button--black"
         @click="openWidgetCreationModal = true">{{this.$t('pages.dashboard.widgetCreation.button')}}</div>
    <q-modal v-model="openWidgetCreationModal"
             class="widget-modal"
             @show="initiateModal"
             @hide="resetModal">
      <div class="modal__header">
        <h4 class="modal-title">Create your own widget</h4>
        <q-icon name="mdi-close"
                class="close-button"
                size="45px"
                @click.native="openWidgetCreationModal = false" />
      </div>
      <div class="modal__content">
        <q-stepper ref="stepper"
                   v-model="currentStep"
                   color="primary"
                   @step="runSteps">
          <OappStep :stepperRef="$refs.stepper"
                    :currentStep="currentStep"
                    @select="selectOapp" />
          <WidgetStep :widgets="selection.oapp && selection.oapp.widgets"
                      :stepperRef="$refs.stepper"
                      :currentStep="currentStep"
                      @select="selectWidget" />
          <ConfigurationStep :widget="selection.widget"
                             :topology="topology"
                             :stepperRef="$refs.stepper"
                             :currentStep="currentStep"
                             @creation="createWidget" />
        </q-stepper>
      </div>
    </q-modal>
  </div>
</template>

<script>
import _ from 'lodash';
import Utils from '@/app/mixins/utils';

import OappStep from '@/app/pages/dashboard/components/oapp-step.vue';
import WidgetStep from '@/app/pages/dashboard/components/widget-step.vue';
import ConfigurationStep from '@/app/pages/dashboard/components/configuration-step.vue';

export default {
  name: 'WidgetCreation',
  components: {
    OappStep,
    WidgetStep,
    ConfigurationStep,
  },
  mixins: [Utils],
  data() {
    return {
      currentStep: null,
      openWidgetCreationModal: false,
      topology: [],
      selection: {
        oapp: null,
        widget: null,
        configuration: null,
      },
    };
  },
  computed: {
    simpleTopology() {
      return this.$store.state.topology.buildings;
    },
    spaceStepClass() {
      return {
        'creation-step--disabled': this.selection.widget === null,
      };
    },
    canCreate() {
      return (
        this.selection.oapp !== null &&
        this.selection.widget !== null &&
        this.selection.configuration !== null &&
        this.selection.configuration.name !== '' &&
        ((this.selection.widget.type !== 'sumGaugeChart' && this.selection.configuration.spaces.length > 0) ||
          (this.selection.widget.type === 'sumGaugeChart' && this.selection.configuration.sum !== null))
      );
    },
  },
  created() {
    if (this.simpleTopology.length === 0) this.$store.dispatch('topology/fetchSimple');
  },
  methods: {
    resetModal() {
      this.selection = {
        oapp: null,
        widget: null,
        configuration: null,
      };
      this.topology = [];
      this.$refs.stepper.reset();
    },
    initiateModal() {
      this.topology = _.cloneDeep(this.simpleTopology);
    },
    selectOapp(oapp) {
      this.selection.oapp = oapp;
      this.$refs.stepper.next();
    },
    selectWidget(widget) {
      this.selection.widget = widget;
    },
    createWidget(widgetConfiguration) {
      this.selection.configuration = widgetConfiguration;
      if (!this.canCreate) return;
      const widget = {
        id: this.selection.widget.id,
        name: this.selection.configuration.name,
        x: 0,
        y: 0,
        oappId: this.selection.oapp.id,
        nodes: this.selection.configuration.spaces,
        config: this.selection.configuration.options
      };
      this.$emit('creation', widget);
      this.openWidgetCreationModal = false;
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.widget-modal >>> .modal-content
  display flex
  flex-direction column
  width 600px
  height 700px
  .modal__header
    display flex
    align-items center
    margin $space-3 0
    padding 0 $space-3
    .modal-title
      margin 0
    .close-button
      margin-left auto
      cursor pointer
  .modal__content
    flex 1

.q-stepper
  width 100%
  height 100%
  box-shadow unset
  .q-stepper-step
    flex 0
    &--current
      flex 5
  .q-stepper-nav
    justify-content flex-end
    height 10%
  & >>> .q-stepper-step-content
    height 100%
  & >>> .q-stepper-header
    background-color black
    box-shadow unset
  & >>> .q-stepper-step-inner
    padding $space-3 !important
    height 100%
  & >>> .q-stepper-dot span
    color black
    font-size $fs-h2
  & >>> .q-stepper-dot span .q-icon // step icon color
    color black
  & >>> .q-stepper-tab.step-waiting .q-stepper-dot // step number dot
    color white
  & >>> .q-stepper-tab.step-active // step label - waiting status
    color $dark-pink
  & >>> .q-stepper-tab.step-waiting // step label - active status
    color white

.creation-step
  display flex
  flex 1
  flex-direction column
  height 90%
  .creation-step__header
    display flex
    flex-direction column
    justify-content center
    margin-bottom $space-2
    .subtitle
      margin-bottom $space-2
      font-size $fs-h2
  &__content
    display flex
    flex-direction column
    height 100%
    .space-selector
      margin $space-2 0
      font-size $fs-h2
      & >>> .vue-treeselect__control
        border-color $black

.button-add-widget
  text-transform uppercase

.back-button
  margin-right $space-1

.create-button
  margin-left auto
  width fit-content
  font-size $fs-h1
</style>