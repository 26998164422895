<template>
  <q-step :order="3"
          :class="stepClass('configuration')"
          name="configuration"
          title="CONFIGURATION">
    <div class="creation-step"
         :class="spaceStepClass">
      <div class="creation-step__header">
        <span v-if="widget"
              class="subtitle">{{widget.description}}</span>
      </div>
      <div class="creation-step__content">
        <!-- name -->
        <text-field v-model="configuration.name"
                    class="widget-name"
                    label="Name"
                    hint="Choose a name for your personalized widget"
                    required />
        <!-- sum -->
        <div v-if="configuration.options && 'sumId' in configuration.options"
            class="widget-customisation">
          <Field label="Sums"
                 hint="Select the sum you want the data of your widget based on"
                 required>
        <q-list v-if="sums"
                class="list list--sum">
          <q-item v-for="(sum, idx) in sums"
                  :key="idx"
                  :class="itemClass(sum)"
                  class="item"
                  @click.native="selectSum(sum)">
            <q-item-main>
              <q-item-tile class="sum-item__title">{{sum.name}}</q-item-tile>
            </q-item-main>
          </q-item>
        </q-list>
          </Field>
        </div>
        <div v-if="widget && widget.required && configuration.options"
             class="widget-customisation">
          <div class="forms">
            <!-- profile -->
            <Field v-if="'profileId' in configuration.options"
                   label="Data profile"
                   required>
              <QSelect v-model="configuration.options.profileId"
                       class="o-select"
                       :options="profiles" />
            </Field>
            <!-- multi profile -->
            <Field v-if="'profileIds' in configuration.options"
                   label="Data profiles"
                   required>
              <treeselect v-model="configuration.options.profileIds"
                          :multiple="true"
                          :options="profiles"
                          :normalizer="profileNormalizer"
                          :limit="4"
                          openDirection="bottom"
                          class="space-selector" />
            </Field>
            <!-- goal -->
            <TextField v-if="'goal' in configuration.options"
                       v-model="configuration.options.goal"
                       label="Goal value"
                       type="number"
                       :suffix="(currentProfile && currentProfile.unit) || (configuration.options.sumId && currentSum.unit)"
                       required />
            <!-- threshold -->
            <TextField v-if="'threshold' in configuration.options"
                       v-model="configuration.options.threshold"
                       label="threshold"
                       type="number"
                       :suffix="currentProfile && currentProfile.unit"
                       required />
            <!-- operation type -->
            <Field v-if="'operationType' in configuration.options"
                   label="Operation type"
                   required>
              <div class="button-list">
                <div class="o-button o-button--hover o-button--shadows"
                     :class="oButtonClass(isOperationTypeSelected('SUM'))"
                     @click="selectOperationType('SUM')">sum</div>
                <div class="o-button o-button--hover o-button--shadows"
                     :class="oButtonClass(isOperationTypeSelected('MEAN'))"
                     @click="selectOperationType('MEAN')">mean</div>
              </div>
            </Field>
            <!-- duration -->
            <Field v-if="'duration' in configuration.options"
                   label="Period length"
                   required>
              <div class="button-list">
                <div class="o-button o-button--hover o-button--shadows"
                     :class="oButtonClass(isDurationSelected(24, 'hours'))"
                     @click="selectDuration(24, 'hours')">last 24h</div>
                <div class="o-button o-button--hover o-button--shadows"
                     :class="oButtonClass(isDurationSelected(1, 'weeks'))"
                     @click="selectDuration(1, 'weeks')">last week</div>
                <div class="o-button o-button--hover o-button--shadows"
                     :class="oButtonClass(isDurationSelected(1, 'months'))"
                     @click="selectDuration(1, 'months')">last month</div>
                <div class="o-button o-button--hover o-button--shadows"
                     :class="oButtonClass(isDurationSelected(1, 'years'))"
                     @click="selectDuration(1, 'years')">last year</div>
              </div>
            </Field>
          </div>
        </div>
        <!-- spaces -->
        <div v-if="configuration.options && !('sumId' in configuration.options)"
class="widget-customisation">
          <Field label="Spaces"
                 hint="Select the spaces you want the data of your widget based on"
                 required>
            <treeselect v-show="widget && topology.length > 0"
                        v-model="configuration.spaces"
                        :multiple="true"
                        :options="topology"
                        :normalizer="normalizer"
                        openDirection="top"
                        class="space-selector" />
          </Field>
        </div>
      </div>
    </div>
    <q-stepper-navigation>
      <q-btn label="Back"
             class="back-button o-button"
             @click="stepperRef.previous()" />
      <q-btn class="o-button o-button--shadows o-button--black o-button--hover"
             :class="createButtonClass"
             label="Create"
             @click="emitCreation" />
    </q-stepper-navigation>
  </q-step>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import TextField from '@/app/components/inputs/text-field.vue';
import Field from '@/app/components/inputs/field.vue';

export default {
  name: 'ConfigurationStep',
  components: {
    Treeselect,
    TextField,
    Field,
  },
  props: {
    widget: {
      type: Object,
      default: null,
    },
    currentStep: {
      type: String,
      default: '',
    },
    stepperRef: {
      type: Object,
      default: null,
    },
    topology: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedSum: null,
      configuration: {
        name: '',
        spaces: [],
        options: {},
      },
    };
  },
  computed: {
    sums() { return this.$store.state.sum.collection; },
    profiles() {
      return this.$store.state.profiles.collection;
    },
    currentProfile() {
      return this.profiles.find(p => p.value === this.configuration.options.profileId);
    },
    currentSum() {
      return this.sums.find(sum =>sum.id === this.configuration.options.sumId);
    },
    formIsComplete() {
      const required = this.configuration.name !== '' &&
                      ((!('sumId' in this.configuration.options) && this.configuration.spaces.length > 0) ||
                        ('sumId' in this.configuration.options && this.configuration.options.sumId))
                        ;
      const optionnal = this.checkOptionnalFields();
      return required && optionnal;
    },
    createButtonClass() {
      return {
        'o-button--disabled': !this.formIsComplete,
      };
    },
  },
  watch: {
    widget(widget) {
      if (!widget) return;
      this.configuration.options = {};
      if (!widget.required) return;
      Object.keys(widget.required).forEach(k => this.$set(this.configuration.options, k, null));
      if (widget.required) this.$store.dispatch('profiles/fetch');
    },
  },
  methods: {
    checkOptionnalFields() {
      if (!this.configuration || !this.configuration.options || !this.widget) return false;
      return Object.keys(this.configuration.options).every(k => Object.keys(this.widget.required).includes(k));
    },
    stepClass(stepName) {
      return {
        'q-stepper-step--current': this.currentStep === stepName,
      };
    },
    oButtonClass(isSelected) {
      return {
        'o-button--black': isSelected,
      };
    },
    emitCreation() {
      this.$emit('creation', this.configuration);
      this.resetForm();
    },
    resetForm() {
      this.configuration = {
        name: '',
        spaces: [],
        options: {}
      };
    },
    normalizer(node) {
      return {
        id: node.uuid,
        label: node.name,
      };
    },
    profileNormalizer(node) {
      return {
        id: node.value,
        label: node.label,
      };
    },
    isDurationSelected(duration, unit) {
      return (
        this.configuration.options &&
        this.configuration.options.duration &&
        this.configuration.options.duration.number === duration &&
        this.configuration.options.duration.string === unit
      );
    },
    selectDuration(duration, unit) {
      this.configuration.options.duration = {
        number: duration,
        string: unit,
      };
    },
    isOperationTypeSelected(operation) {
      return this.configuration.options.operationType === operation;
    },
    selectOperationType(operation) {
      this.configuration.options.operationType = operation;
    },
    itemClass(sum) {
      return {
        'item--selected': sum.id === this.configuration.options.sumId,
      };
    },
    selectSum(sum) {
      this.configuration.options.sumId = sum.id;
    },
  },
};
</script>

<style lang='stylus' scoped>
@import '~variables'

.creation-step
  display flex
  flex 1
  flex-direction column
  height 90%
  .creation-step__header
    display flex
    flex-direction column
    justify-content center
    margin-bottom $space-2
    .subtitle
      margin-bottom $space-2
      font-size $fs-h2
  &__content
    display flex
    flex-direction column
    height 100%
    .space-selector
      font-size $fs-h2
      & >>> .vue-treeselect__control
        border-color $black


.list
  padding 0
  height 100%
  border unset
  &--sum
    overflow auto
    max-height 190px
    height auto
  .item
    margin-bottom $space-1
    border 1px solid $grey
    border-radius $border-radius-smooth
    background-color white
    font-size $fs-h2
    cursor pointer
    &:hover
      background-color darken(white, 2%)
    &--selected, &--selected:hover
      background-color black
      color white
    &:last-child
      margin-bottom 0

.button-list
  display flex
  justify-content center
  .o-button
    margin 0 $space-1

.q-stepper
  .q-stepper-nav
    justify-content flex-end
    height 10%

.back-button
  margin-right $space-1
</style>